import React from 'react';
import { Link } from 'react-router-dom';
import speakingimg from '../img/speaking.jpg';
import { Fragment, useState, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { getExercise, retrieveS3DataAPI, sendHashToS3API } from './CohortAPI';
import { useSelector } from 'react-redux';
import tokenImgUrl from '../img/token.png';
import Spinner from './Spinner';
import { updateUserAttributesAPI } from './UpdateUserAttributesAPI';
import { updateTokensAPI } from './TokenAPI';
import hash from 'object-hash';

let phrase = {};
if ((localStorage.getItem("dailyLessonsFromAPI")!=null)){
  phrase=JSON.parse(localStorage.getItem("currentPhrase"));
}else{
  phrase= {"phraseOfTheDay":{
    "text":"text",
    "translation":"translation"
  }}
}
let userName = localStorage.getItem("firstName");

export const speakingPractice = {
  original_phrase:"default",
  text1: `${localStorage.getItem("firstName")}, let's do a speaking exercise that'll help you ask for and understand directions in Italian. This is a practical skill you'll definitely use during your trip. The key phrase we'll work on is Dove si trova il bagno? which means Where is the bathroom?`,
  text2: "First, let's practice saying the phrase together a few times to get comfortable with the pronunciation. Repeat after me:", 
  text3: "Dove si trova il bagno?",
  text4: "Great, once you feel confident with that, let's move on to a role-playing scenario.",
  text5: "Imagine you're in a café. You've just finished your coffee, and now you need to find the restroom. I'll be the café barista, and you'll start by asking me where the bathroom is. After I respond, you'll thank me and then ask for another location, like the nearest ATM or a recommended restaurant. Let's begin.",
  rolePlay1: [
          {id:11, user: 'self', content: "Excuse me, \"Dove si trova il bagno?\"", player: "You"},
          {id:12, user: 'tutor', content: "È giù per il corridoio, sulla destra", player: "Me as the Barista"},
          {id:13, user: 'tutor', content: "Now, you respond by saying thank you", player: "Me as the Barista"},
          {id:14, user: 'self', content: "Grazie!", player: "You"},
  ],
  rolePlay2Scenario: `Next, let's ask about an ATM. You can say: "Dove posso trovare un bancomat?"`,
  rolePlay2ScenarioSub: "I'll respond, and then you thank me again. Ready?",
  rolePlay2: [
    {id:21, user: 'self', content: "Dove posso trovare un bancomat?", player: "You"},
    {id:22, user: 'tutor', content: "C'è un bancomat all'angolo, vicino alla piazza", player: "Me as the Barista"},
    {id:23, user: 'self', content: "Grazie!", player: "You"},
  ],
  rolePlay3Scenario: `And now, let's ask for a recommendation for a place to eat. You can ask: "Può consigliarmi un buon ristorante qui vicino?"`,
  rolePlay3: [
    {id:31, user: 'self', content: "Può consigliarmi un buon ristorante qui vicino?", player: "You"},
    {id:32, user: 'tutor', content: `Certamente! Il ristorante "La Tavola Calda" è eccellente e si trova solo a due strade da qui.`, 
    player: "Me as the Barista"},
    {id:33, user: 'self', content: "Grazie mille!", player: "You"},
  ],


  text6: `Perfect, ${userName}. Remember, it's all about practice. The more you use these phrases, the more natural they will become. Now, let's go over anything you found challenging, and we can practice it a few more times. How did that feel?`             
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export function SpeakingExercise() {

  const [openSpeaking, setOpenSpeaking] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  console.log("Opening Speaking Module");
  // fetching code
  const user = useSelector(state => state.user.userInfo);
  let lang = localStorage.getItem("learningLanguage");
  let native = localStorage.getItem("nativeLanguage");

  console.log(user);

  if(user != null){
    lang = user.attributes['custom:learning-language'];
    native = user.attributes['custom:native-language'];
  }
  const [openListening, setOpenListening] = useState(false);
  const { t } = useTranslation();
  

  let learnExercise =  useCallback(() => { 
    let speakingExercise = JSON.parse(localStorage.getItem("speakingExercise"));
    console.log("USER = " + user);
    if(user != null){
      lang = user.attributes['custom:learning-language'];
      native = user.attributes['custom:native-language'];
    }else{
      lang = localStorage.getItem("learningLanguage");
      native = localStorage.getItem("currentLanguage");
    }
    setIsFetching(true);
    phrase=JSON.parse(localStorage.getItem("currentPhrase"));
    if(!speakingExercise || speakingExercise.original_phrase !== phrase.phraseOfTheDay.text ){
              const timer = setTimeout(() => {
                learnExercise();
                console.log("P8: no Listening lesson yet, running loop again");
                console.log(speakingExercise.original_phrase);
                console.log( phrase.phraseOfTheDay.text);
              }, 1000);
              return () => clearTimeout(timer);
    }
    if (phrase){
      let speakingExerciseState = localStorage.getItem("speakingExerciseState");
      phrase = JSON.parse(localStorage.getItem("currentPhrase"));
      if (!speakingExercise || speakingExercise.original_phrase !== phrase.phraseOfTheDay.text ){
        updateTokensAPI(2).then(function(response){
          if (!response){
            alert('update tokens failed');
            setIsFetching(false);
            return false;
          }else if (response.body['message'] == 'Insufficient Tokens.'){
              setIsFetching(false);
              console.log('Insufficient funds, please reload tokens..........');
              alert('Insufficient Funds ..............');
              return false;
          }else{
              let data = [{ "Name": "custom:token-balance", "Value": response.body["tokens left"].toString()}];
              updateUserAttributesAPI(data);
              // insert cached lookup code here
              retrieveS3DataAPI(phrase.phraseOfTheDay.text, "speaking").then(function(response){
                if(response){
                  speakingPractice.text1=response.text1;
                  speakingPractice.text2=response.text2;
                  speakingPractice.text3=response.text3;
                  speakingPractice.text4=response.text4;
                  speakingPractice.text5=response.text5;
                  speakingPractice.text6=response.text6;
                  speakingPractice.rolePlay1=response.rolePlay1;
                  speakingPractice.rolePlay2=response.rolePlay2;
                  speakingPractice.rolePlay3=response.rolePlay3;
                  speakingPractice.rolePlay2Scenario=response.rolePlay2Scenario;
                  speakingPractice.rolePlay2ScenarioSub=response.rolePlay2ScenarioSub;
                  speakingPractice.original_phrase=phrase.phraseOfTheDay.text;
                  speakingExerciseState="cached";
                  localStorage.setItem("speakingExercise",JSON.stringify(speakingPractice)); 
                  localStorage.removeItem("speakingExerciseState");
                  localStorage.setItem("speakingExerciseState","cached"); 
                  setIsFetching(false);
                  setOpenSpeaking(true);  
                }else{
                  getExercise(lang, native, phrase.phraseOfTheDay.text, "speaking", JSON.stringify(speakingPractice)).then(function(data){
                    speakingPractice.text1=data.text1;
                    speakingPractice.text2=data.text2;
                    speakingPractice.text3=data.text3;
                    speakingPractice.text4=data.text4;
                    speakingPractice.text5=data.text5;
                    speakingPractice.text6=data.text6;
                    speakingPractice.rolePlay1=data.rolePlay1;
                    speakingPractice.rolePlay2=data.rolePlay2;
                    speakingPractice.rolePlay3=data.rolePlay3;
                    speakingPractice.rolePlay2Scenario=data.rolePlay2Scenario;
                    speakingPractice.rolePlay2ScenarioSub=data.rolePlay2ScenarioSub;
                    speakingPractice.original_phrase=phrase.phraseOfTheDay.text;
                    speakingPractice.state="cached";
                    localStorage.setItem("speakingExercise",JSON.stringify(speakingPractice)); 
                    localStorage.removeItem("speakingExerciseState");
                    localStorage.setItem("speakingExerciseState","cached"); 
                    setIsFetching(false);
                    setOpenSpeaking(true);   
                  })
                }
              })
          }
        })
      }else if(speakingExerciseState==="floating"){
        updateTokensAPI(2).then(function(response){
          if (!response){
            alert('update tokens failed');
            setIsFetching(false);
            return false;
          }else if (response.body['message'] == 'Insufficient Tokens.'){
              setIsFetching(false);
              console.log('Insufficient funds, please reload tokens..........');
              alert('Insufficient Funds ..............');
              return false;
          }else{
              let data = [{ "Name": "custom:token-balance", "Value": response.body["tokens left"].toString()}];
              updateUserAttributesAPI(data);
              speakingPractice.text1=speakingExercise.text1;
              speakingPractice.text2=speakingExercise.text2;
              speakingPractice.text3=speakingExercise.text3;
              speakingPractice.text4=speakingExercise.text4;
              speakingPractice.text5=speakingExercise.text5;
              speakingPractice.text6=speakingExercise.text6;
              speakingPractice.rolePlay1=speakingExercise.rolePlay1;
              speakingPractice.rolePlay2=speakingExercise.rolePlay2;
              speakingPractice.rolePlay3=speakingExercise.rolePlay3;
              speakingPractice.rolePlay2Scenario=speakingExercise.rolePlay2Scenario;
              speakingPractice.rolePlay2ScenarioSub=speakingExercise.rolePlay2ScenarioSub;
              speakingPractice.original_phrase=phrase.phraseOfTheDay.text;
              speakingExerciseState="cached";
              localStorage.setItem("speakingExercise",JSON.stringify(speakingPractice));
              localStorage.removeItem("speakingExerciseState");
              localStorage.setItem("speakingExerciseState","cached");     
              setIsFetching(false);
              setOpenSpeaking(true);
          }
        })
      } else {
          speakingPractice.text1=speakingExercise.text1;
          speakingPractice.text2=speakingExercise.text2;
          speakingPractice.text3=speakingExercise.text3;
          speakingPractice.text4=speakingExercise.text4;
          speakingPractice.text5=speakingExercise.text5;
          speakingPractice.text6=speakingExercise.text6;
          speakingPractice.rolePlay1=speakingExercise.rolePlay1;
          speakingPractice.rolePlay2=speakingExercise.rolePlay2;
          speakingPractice.rolePlay3=speakingExercise.rolePlay3;
          speakingPractice.rolePlay2Scenario=speakingExercise.rolePlay2Scenario;
          speakingPractice.rolePlay2ScenarioSub=speakingExercise.rolePlay2ScenarioSub;
          speakingPractice.original_phrase=phrase.phraseOfTheDay.text;   
          speakingPractice.state="cached";
          localStorage.setItem("speakingExercise",JSON.stringify(speakingPractice));
          localStorage.removeItem("speakingExerciseState");
          localStorage.setItem("speakingExerciseState","cached");     
          setIsFetching(false);
          setOpenSpeaking(true);
      }
    }
  }, [])

  return (
    <>
      <li key="speakingid">
          <img className="mx-auto h-72 w-72 rounded-full" src={speakingimg} alt="" />
          <Link className="relative px-7 py-2 font-medium text-white group mt-4" onClick={() => learnExercise(true)}>
            <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-purple-500 group-hover:bg-purple-700 group-hover:skew-x-12"></span>
            <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-purple-700 group-hover:bg-purple-500 group-hover:-skew-x-12"></span>
            <span className="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-purple-600 -rotate-12"></span>
            <span className="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-purple-400 -rotate-12"></span>
            <span className="relative">{t('Speaking')}</span>
            <div className="absolute -top-0 -right-0 -mt-3 -mr-4 px-3 py-1 h-9 w-9 rounded-full
                  bg-cover bg-center" style={{backgroundImage: `url(${tokenImgUrl})`}}>
            <div className="font-extrabold text-purple-500 text-lg pr-1 pt-0.5">2</div>
          </div>
          </Link>
      </li>

      {isFetching && 
          <div id="spinner" className="w-full">
            <Spinner/>
          </div>        
      }
    <Transition.Root show={openSpeaking} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenSpeaking}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-800 hover:text-gray-500 sm:right-4 sm:top-4 md:right-4 md:top-4 lg:right-4 lg:top-4"
                    onClick={() => setOpenSpeaking(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:items-center lg:gap-x-8">


                    {/* Speaking Exercise */}
                    <div className="shadow sm:rounded-lg col-span-12 p-2 "> 

 
                      <div className="bg-sky-600">
                        <div className="px-4 py-5 sm:p-6">
                          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-4xl">{t('Speaking Lesson')}</h2>
                        </div>
                      </div>
                      
                      <div className="chat chat-start m-4">
                        <div className="chat-bubble">
                          {speakingPractice.text1}
                        </div>
                      </div>
                      
                      <div className="chat chat-start m-4">
                        <div className="chat-bubble">
                          <p>{speakingPractice.text2}</p>
                        </div>
                      </div>

                      <div className="chat chat-start m-4">
                        <div className="chat-bubble">
                          <p>{speakingPractice.text3}</p>
                        </div>
                      </div>

                      <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 m-4 border-r-4">
                        <div className="flex">
                          <div className="ml-3">
                            <p className="text-md text-slate-800 pb-4">
                              {speakingPractice.text4}
                            </p>
                            <p className="text-md text-slate-800">
                              {speakingPractice.text5}
                            </p>
                            {/* Role Play */}
                            {speakingPractice.rolePlay1.map((role) => (
                              <div key={role.id} className={classNames(
                                role.user === "self" ? 'chat-start' : 'chat-end',
                                'chat'
                                )}>
                                <div className="chat-image avatar">
                                  <div className="w-10 rounded-full">
                                      {role.user === "self" ? <UserCircleIcon /> : <img alt={role.player} src={speakingimg} />}  
                                  </div>
                                </div>
                                <div className="chat-header">{role.player}</div>
                                <div className="chat-bubble chat-bubble-warning">{role.content}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>       

                      <div className="border-l-4 border-green-400 bg-green-50 p-4 m-4 border-r-4">
                        <div className="flex">
                          <div className="ml-3">
                            <p className="text-md text-slate-800 pb-4">
                              {speakingPractice.rolePlay2Scenario}
                            </p>
                            <p className="text-md text-slate-800">
                              {speakingPractice.rolePlay2ScenarioSub}
                            </p>
                            {/* Role Play 2*/}
                            {speakingPractice.rolePlay2.map((role) => (
                              <div key={role.id} className={classNames(
                                role.user === "self" ? 'chat-start' : 'chat-end',
                                'chat'
                                )}>
                                <div className="chat-image avatar">
                                  <div className="w-10 rounded-full">
                                      {role.user === "self" ? <UserCircleIcon /> : <img alt={role.player} src={speakingimg} />}  
                                  </div>
                                </div>
                                <div className="chat-header">{role.player}</div>
                                <div className="chat-bubble chat-bubble-success">{role.content}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>   

                      <div className="border-l-4 border-teal-400 bg-teal-50 p-4 m-4 border-r-4">
                        <div className="flex">
                          <div className="ml-3">
                            <p className="text-md text-slate-800 pb-4">
                              {speakingPractice.rolePlay3Scenario}
                            </p>
                            {/* Role Play 3*/}
                            {speakingPractice.rolePlay3.map((role) => (
                              <div key={role.id} className={classNames(
                                role.user === "self" ? 'chat-start' : 'chat-end',
                                'chat'
                                )}>
                                <div className="chat-image avatar">
                                  <div className="w-10 rounded-full">
                                      {role.user === "self" ? <UserCircleIcon /> : <img alt={role.player} src={speakingimg} />}  
                                  </div>
                                </div>
                                <div className="chat-header">{role.player}</div>
                                <div className="chat-bubble chat-bubble-accent">{role.content}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>  




                      <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 m-4">
                        <div className="flex">
                          <div className="ml-3">
                            <p className="text-md text-slate-800">
                              {speakingPractice.text6}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  );
}

