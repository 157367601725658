import { levels } from './LearnSettings';
import hash from 'object-hash';
import axios from 'axios'



function buildHash(user){
  let currentContext=user.attributes['custom:context'];
  let currentLanguage=user.attributes['custom:learning-language'];
  let learningLevel=user.attributes['custom:learning-level'];
  let nativeLanguage = user.attributes['custom:native-language'];
  let learningLevelValue = 3;
  levels.forEach((level) => {
    if(level.name === learningLevel){
        learningLevelValue = level.value
    }
  })
  let settingsHashObject={
    currentContext:currentContext,
    currentLanguage:currentLanguage,
    learningLevel:learningLevelValue,
    nativeLanguage:nativeLanguage
  }
  let settingsHash= hash(settingsHashObject);
  console.log("Hashed value = " + settingsHash);
  return settingsHash;
}
export async function retrievePhrasesAPI(user, path){
  console.log('calling retrieve exisiting phrases api ..........');

  let hash = buildHash(user);
  let data = {
    "userId":hash,
    "path":"cohorts"
  }
  let config = {
    method: 'post',
    url: 'https://kcdz5wh26yljczpgrdsyablppm0freov.lambda-url.us-west-2.on.aws/',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  try {
    let response = await axios.request(config);
    // if response is null, activate new AI query and respond with result
    if(response.data.Code === "NoSuchKey"){
      console.log("no cohort found, retrieving new cohort from AI")
      let newPhrases = await getNewPhrasesAPI(user);
      return newPhrases;

    }else{
      console.log("cohort data is : "+ JSON.stringify(response.data));
      localStorage.setItem("dailyLessonsFromAPI",JSON.stringify(response.data));
      localStorage.setItem("dailyPhrases",JSON.stringify(response.data));
      let cohortData = response.data;
      return cohortData;
    }
  } catch (error) {
    console.error(error);
  }
}
export async function retrieveCohortAPI (user, path)  {
  console.log('calling retrieve exisiting cohort api ..........');

  let customAttrs =
  {
    "context": user.attributes['custom:context'],
   "level": user.attributes['custom:learning-level'],
    "native-language": user.attributes['custom:native-language'],
    "learning-language": user.attributes['custom:learning-language']
  }
  let hash = buildHash(customAttrs);
  let data = {
    "userId":hash,
    "path":"cohorts"
  }
  let config = {
    method: 'post',
    url: 'https://kcdz5wh26yljczpgrdsyablppm0freov.lambda-url.us-west-2.on.aws/',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  try {
    let response = await axios.request(config);
    // if response is null, activate new AI query and respond with result
    if(response.data.Code != "NoSuchKey"){
      console.log("cohort data is : "+ JSON.stringify(response.data));
      localStorage.setItem("dailyLessonsFromAPI",JSON.stringify(response.data));
      return response.data;
    }else{
      console.log("no cohort found, retrieving new cohort from AI")
      return getNewLessonsAPI(user);
    }
  } catch (error) {
    console.error(error);
  }
}
export async function sendCohortAPI(user, cohort, path){

  console.log('sending cohort to persist .........')

  let hash = buildHash(user);
  let data = JSON.stringify({
    "userId": hash,
    "custom:cohort": cohort,
    "path":path
  });
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://lgjcxqq574p7ldbvgzktimutkm0ogqas.lambda-url.us-west-2.on.aws/',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  axios.request(config)
  .then((response) => {
    console.log("sent cohort to S3 ........."+ hash + ".json");
  })
  .catch((error) => {
    console.log(error);
  });
}
export async function getNewPhrasesAPI(user) {

  console.log("retrieving new lessons from API .......");
  
  let currentContext=user.attributes['custom:context'];
  let currentLanguage=user.attributes['custom:learning-language'];
  let learningLevel=user.attributes['custom:learning-level'];
  let nativeLanguage=user.attributes['custom:native-language'];

  let data = JSON.stringify({
      "role": "user",
      "prompt": `You are a language tutor helping a native ${nativeLanguage} speaking adult named ${localStorage.getItem("firstName")}  with ID# 999999 learn ${currentLanguage}.Consider if the person you are tutoring knows the language a little more than someone who knows nothing about the language. They want to learn at a level of ${learningLevel}  (which you won’t mention in the response). They are planning a personal trip to a country which speaks ${currentLanguage} in the next few months. Offer three Phrases-of-the-day with at least 5 words with correct punctuation in the context of ${currentContext}.  Formulate the response in a json object. Do not reply to this query with anything else but the json object text. Use the following format: [{\r\n  \"phraseOfTheDay\": {\r\n    \"text\": \"\"}]. Do not return any phrases that are translations of 'where is the bus stop?', 'where is the train station', or 'can you pass me the salt'`,
      "model": "gpt-4o"
  });
  
  let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://z6hiegk6imdth2nijmtfhbwsay0hxkoa.lambda-url.us-west-2.on.aws/',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

  try {
    let response = await axios.request(config);
    let returnData = response.data;
      console.log("phrases cohort is : "+JSON.stringify(response.data));
      localStorage.setItem("dailyLessonsFromAPI",JSON.stringify(response.data));
      sendCohortAPI(user, response.data, "phrases");
      return response.data;
  } catch (error) {
      console.error(error);
  }
}
export async function getNewLessonsFromPhrasesAPI(user, phrases) {

  console.log("retrieving new lessons from phrases API .......");
  console.log(phrases);
  

  let currentContext=user.attributes['custom:context'];
  let currentLanguage=user.attributes['custom:learning-language'];
  let learningLevel=user.attributes['custom:learning-level'];
  let nativeLanguage=user.attributes['custom:native-language'];
  let learningLevelValue = 3;
  
 
  levels.forEach((level) => {
    if(level.name === learningLevel){
        learningLevelValue = level.value
    }
  });

  let data = JSON.stringify({
      "role": "user",
      "prompt": `You are a language tutor helping a native ${nativeLanguage} speaking person learn ${currentLanguage}. Assume the ${currentLanguage} is at a ${learningLevel} level and wants lessons presented at a ${learningLevel} level (which you won’t mention). Today they are learning the following three Phrases-of-the-day, ${phrases[0].phraseOfTheDay.text}, ${phrases[1].phraseOfTheDay.text}, ${phrases[2].phraseOfTheDay.text}, Provide the Translation, a Phonetic Pronunciation, a Vocabulary Expansion, a Grammar tip, a Cultural Insight, a Conversational Tip and a Role Play which uses the phrase in speaking to someone in the ${currentContext}. Speak to them in the first person in native ${nativeLanguage} and use their name to make it sound personal. Formulate the response in a json object. Do not reply to this query with anything else but the json object text. Use the following format: [{\r\n  \"phraseOfTheDay\": {\r\n    \"text\": \"\", {\r\n    \"translation\": \"\",\r\n    \"pronunciation\": \"\",\r\n    \"vocabularyExpansion\": {\r\n\r\n    },\r\n    \"grammarTip\": \"\",\r\n    \"culturalInsight\": \"\",\r\n    \"conversationalTip\": \"\",\r\n    \"rolePlay\": {\r\n\"situation\": \"\",\r\n      \"firstPerson\": \"\",\r\n      \"secondPerson\": \"\",\r\n      \"script\": {\r\n        \"Marco\": \"\",\r\n        \"Tour guide\": \"\",\r\n        \"Marco2\": \"\"\r\n      }\r\n    }\r\n  }\r\n }]"`,

      "model": "gpt-4o"
  });
  
  let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://z6hiegk6imdth2nijmtfhbwsay0hxkoa.lambda-url.us-west-2.on.aws/',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

  try {
    let response = await axios.request(config);
    sendCohortAPI(user,response.data, "cohorts");
    localStorage.setItem("dailyLessonsFromAPI",JSON.stringify(response.data));
    return response.data;
  } catch (error) {
      console.error(error);
  }
}
export async function getNewLessonsAPI(user) {
  console.log("retrieving new lessons from API .......");
  
  let currentContext=user.attributes['custom:context'];
  let currentLanguage=user.attributes['custom:learning-language'];
  let learningLevel=user.attributes['custom:learning-level'];
  let nativeLanguage=user.attributes['custom:native-language'];
  let learningLevelValue = 3;
  levels.forEach((level) => {
    if(level.name === learningLevel){
        learningLevelValue = level.value
    }
  });
  let data = JSON.stringify({
      "role": "user",
      "prompt": `You are a language tutor helping a native ${nativeLanguage} speaking adult named ${localStorage.getItem("firstName")}  with ID# 999999 learn ${currentLanguage}.Consider if the person you are tutoring knows the language a little more than someone who knows nothing about the language. They want to learn at a level of ${learningLevel}  (which you won’t mention in the response). They are planning a personal trip to a country with speaks ${currentLanguage} in the next few months. Offer three Phrases-of-the-day with at least 5 words with correct punctuation in the context of ${currentContext}. Make the phrases different than what you have offered this student before. Provide the Translation, a Phonetic Pronunciation, a Vocabulary Expansion, a Grammar tip, a Cultural Insight, a Conversational Tip and a Role Play which uses the phrase in speaking to a tour guide, all in their native language of ${nativeLanguage}. Speak to them in the first person in ${nativeLanguage} and use their name to make it sound personal. Formulate the response in a json object. Do not reply to this query with anything else but the json object. Use the following format: [{\r\n  \"phraseOfTheDay\": {\r\n    \"text\": \"\", {\r\n    \"translation\": \"\",\r\n    \"pronunciation\": \"\",\r\n    \"vocabularyExpansion\": {\r\n\r\n    },\r\n    \"grammarTip\": \"\",\r\n    \"culturalInsight\": \"\",\r\n    \"conversationalTip\": \"\",\r\n    \"rolePlay\": {\r\n      \"situation\": \"\",\r\n      \"firstPerson\": \"\",\r\n      \"secondPerson\": \"\",\r\n      \"script\": {\r\n        \"Marco\": \"\",\r\n        \"Tour guide\": \"\",\r\n        \"Marco2\": \"\"\r\n      }\r\n    }\r\n  }\r\n }]"`,
      "model": "gpt-4o"
  });
  
  let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://z6hiegk6imdth2nijmtfhbwsay0hxkoa.lambda-url.us-west-2.on.aws/',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

  try {
    let response = await axios.request(config);
    localStorage.setItem("dailyLessonsFromAPI", JSON.stringify(response.data));
    sendCohortAPI(user,response.data, "cohorts");
    return response.data;
  } catch (error) {
      console.error(error);
  }
}
export async function getExercise(lang, native, phrase, type, structure) {
  console.log("retrieving new lessons from API .......");
  let learningLevelValue = 3;
  let data = JSON.stringify({
      "role": "user",
      "prompt": `You are a language tutor helping a native ${native} speaking adult named ${localStorage.getItem("firstName")} with ID# 999999 learn ${lang}. They are planning a personal trip in the next few months. Construct a ${type} exercise that would take them about 5 minutes to complete based on the phrase: "${phrase}". Speak to them in the first person in their native language and use their name to make it sound personal. Make sure that the result is in the ${native} language and is produced as a JSON object format, and return only a properly structured JSON object, wihout indicator text that it is a JSON. Use following formatting: ${structure}, to generate the lessong. Make sure that you are generating new content for this lesson, and not repeating the content in the example structure.`,
      "model": "gpt-4o"
  });
  
  let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://z6hiegk6imdth2nijmtfhbwsay0hxkoa.lambda-url.us-west-2.on.aws/',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

  try {
    let response = await axios.request(config);
    // response.data.state="floating";// this has been purchased by app, but not yet by the user
    localStorage.setItem(`${type}Exercise`, JSON.stringify(response.data));
    localStorage.setItem(`${type}ExerciseState`, "floating");
    let hashPhrase = hash(phrase);
    console.log("S3 SEND TO phrase = "+ phrase)
    sendHashToS3API(phrase, response.data, type);
    return response.data;
  } catch (error) {
      console.error(error);
  }
}
export async function getListeningExercise(lang, native, phrase, type, structure) {
  //// getListeningExercise(lang, native, phrase.phraseOfTheDay.text, "listening", JSON.stringify(listeningPractice))

  console.log("retrieving new lessons from API .......");
  let learningLevelValue = 3;
  let data = JSON.stringify({
      "role": "user",
      "prompt": `You are a language tutor helping a native ${native} speaking adult named ${localStorage.getItem("firstName")} with ID# 999999 learn ${lang}. They are planning a personal trip in the next few months. Construct a ${type} exercise that would take them about 5 minutes to complete based on the phrase: "${phrase}". Create a conversational response to a person who walks up and says: ${phrase} to another person on the street. Put this response into the "audioResponse" data field in the return json. Speak to the user in the first person in their native language and use their name to make it sound personal. Make sure that the result is in the ${native} language and is produced as a JSON object format, and return only a properly structured JSON object, wihout indicator text that it is a JSON. Use following formatting: ${structure}, to generate the lessong. Make sure that you are generating new content for this lesson, and not repeating the content in the example structure.`,
      "model": "gpt-4o"
  });
  
  let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://z6hiegk6imdth2nijmtfhbwsay0hxkoa.lambda-url.us-west-2.on.aws/',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

  try {
    let response = await axios.request(config);
    // localStorage.setItem(`${type}Exercise`, JSON.stringify(response.data));
    // window.alert(phrase);
    sendHashToS3API(phrase, response.data, type);
    return response.data;
  } catch (error) {
      console.error(error);
  }
}
export async function getUserAttrs(idPool, userName){
  
  var data = JSON.stringify({
    "data": {
      "userPoolId": "us-west-2_ZH0ki5m5n",
      "userName": userName
    }
  });
  
  var config = {
    method: 'post',
    url: 'https://k3dkwhol7wc3jizu4qxge5qini0zoxta.lambda-url.us-west-2.on.aws/',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return error;
  });
  
}
export async function sendHashToS3API(data2Hash, rawData, bucket){
  let hashedData= hash(JSON.stringify(data2Hash));
  console.log("data2Hash = "+ data2Hash);
  console.log("data2Hash = "+ hashedData);

  let data = JSON.stringify({
    "userId": hashedData,
    "custom:cohort": rawData,
    "path":bucket
  });
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://lgjcxqq574p7ldbvgzktimutkm0ogqas.lambda-url.us-west-2.on.aws/',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  axios.request(config)
  .then((response) => {
    console.log("sent cohort to S3 ........."+ data2Hash + hashedData + ".json");
  })
  .catch((error) => {
    console.log(error);
  });
}
export async function retrieveS3DataAPI (data2Hash, path)  {
  data2Hash = data2Hash.replace('"','');
  data2Hash = data2Hash.replace('"','');
  console.log("data2Hash = "+ data2Hash);
  let hashedData = hash(JSON.stringify(data2Hash));
  console.log("data2Hash = "+ hashedData);

  console.log('retrieving exisiting cohort api S3 for hash... '+ data2Hash + hashedData);

  // window.alert(hashedData);
  let data = {
    "userId":hashedData,
    "path":path
  }
  let config = {
    method: 'post',
    url: 'https://kcdz5wh26yljczpgrdsyablppm0freov.lambda-url.us-west-2.on.aws/',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  try {
    let response = await axios.request(config);
    // if response is null, activate new AI query and respond with result
    if(response.data.Code != "NoSuchKey"){
      console.log(JSON.stringify(response.data));
      localStorage.setItem(path+"Exercise",JSON.stringify(response.data));
      return response.data;
    }else{
      console.log("no cohort found, retrieving new cohort from AI");
      return false;
    }
  } catch (error) {
    console.error(error);
  }
}