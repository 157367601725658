import React from 'react';
import { Link } from 'react-router-dom';
import readingimg from '../img/reading.jpg';
import { Fragment, useState, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next';
import tokenImgUrl from '../img/token.png';
import {getExercise, retrieveS3DataAPI} from './CohortAPI';
import { useSelector } from 'react-redux';
import Spinner from './Spinner';
import { updateTokensAPI } from './TokenAPI';
import { updateUserAttributesAPI } from './UpdateUserAttributesAPI';



let phrase = {};
if ((localStorage.getItem("dailyLessonsFromAPI")!=null)){
  phrase=JSON.parse(localStorage.getItem("currentPhrase"));
}else{
  phrase= {"phraseOfTheDay":{
    "text":"text",
    "translation":"translation"
  }}
}

console.log("phrases");
console.log(phrase);
let userName = localStorage.getItem("firstName");
export const readingPractice = {
  original_phrase:"default",
  readingIntro: `${localStorage.getItem("firstName")}, you're going to read a short paragraph that I've written for you. It describes a situation where you might need to find a bathroom while exploring a town. After the paragraph, I'll provide you a few questions to answer based on what you've read. Take your time to read it carefully and then try to answer the questions. If you come across any words you don't know, make a note of them, and we'll go over them together.`,
  readingParagraph: `Mentre esplori le vie acciottolate di un antico borgo italiano, ti accorgi che hai bisogno di trovare un bagno. Passi accanto a una gelateria colorata e decidi di chiedere aiuto. Ti avvicini al proprietario e con un sorriso chiedi: \"Scusi, dove si trova il bagno?\" Il proprietario risponde gentilmente: \"Il bagno? Segua la strada fino alla piazza, poi giri a sinistra e troverà il bagno pubblico accanto alla fontana.\" Ringrazi il proprietario e segui le sue indicazioni. Mentre cammini, ammiri le vetrine dei negozi e il profumo dei fiori freschi nel mercato. Dopo pochi minuti, arrivi alla piazza, giri a sinistra e vedi la fontana scintillante al sole. Il bagno si trova proprio lì, esattamente come ti era stato descritto.`,
  questions: [
              {id: "1", ques: "Where do you find yourself when you realize you need to find a bathroom?" } , 
              {id: "2", ques: "Who do you decide to ask for help, and what is their business?" },
              {id: "3", ques: "How does the owner describe where to find the bathroom?"},
              {id: "4", ques: "What do you pass as you follow the owner's directions?"},
              {id: "5", ques: "Where is the bathroom located in the town square?"},
            ],
}

const ListItemNumber = ({ count, text }) => {
  return (
    <li key={count} className="text-body-color dark:text-dark-6 flex text-base mb-2">
      <span className="bg-green-500 mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded-full text-base text-white">
        {count}
      </span>
      {text}
    </li>
  );
};


export function ReadingExercise() {

  const [isFetching, setIsFetching] = useState(false);
  console.log("Opening Reading Module");
  const user = useSelector(state => state.user.userInfo);
  let lang = localStorage.getItem("learningLanguage");
  let native = localStorage.getItem("nativeLanguage");
  console.log(user);
  if(user){
    lang = user.attributes['custom:learning-language'];
    native = user.attributes['custom:native-language'];
  }
  const [openReading, setOpenReading] = useState(false);
  const { t } = useTranslation();

  let learnExercise =  useCallback(() => { 
    let readingExercise = JSON.parse(localStorage.getItem("readingExercise"));
    if(user != null){
      lang = user.attributes['custom:learning-language'];
      native = user.attributes['custom:native-language'];
    }else{
      lang = localStorage.getItem("learningLanguage");
      native = localStorage.getItem("currentLanguage");
    }
    setIsFetching(true);
    phrase=JSON.parse(localStorage.getItem("currentPhrase"));
    if(!readingExercise || readingExercise.original_phrase !== phrase.phraseOfTheDay.text ){
              const timer = setTimeout(() => {
                learnExercise();
                console.log("P8: no Listening lesson yet, running loop again");
                console.log(readingExercise.original_phrase);
                console.log( phrase.phraseOfTheDay.text);
              }, 1000);
              return () => clearTimeout(timer);
    }
    if (phrase){
      let readingExerciseState = localStorage.getItem("readingExerciseState");
      phrase = JSON.parse(localStorage.getItem("currentPhrase"));
      if (!readingExercise){
        updateTokensAPI(2).then(function(response){
          if (!response){
            alert('update tokens failed');
            setIsFetching(false);
            return false;
          }else if (response.body['message'] == 'Insufficient Tokens.'){
              setIsFetching(false);
              console.log('Insufficient funds, please reload tokens..........');
              alert('Insufficient Funds ..............');
              return false;
          }else{
              let data = [{ "Name": "custom:token-balance", "Value": response.body["tokens left"].toString()}];
              updateUserAttributesAPI(data);
              localStorage.removeItem("readingExerciseState");
              localStorage.setItem("readingExerciseState","cached"); 
              localStorage.setItem("readingExercise",JSON.stringify(readingExercise)); 
              //cache search
              retrieveS3DataAPI(phrase.phraseOfTheDay.text, "reading").then(function(response){
                if(response!="false"){
                  readingPractice.readingIntro=response.readingIntro;
                  readingPractice.readingParagraph=response.readingParagraph;
                  readingPractice.questions=response.questions;
                  readingPractice.original_phrase=phrase.phraseOfTheDay.text;
                  localStorage.setItem("readingExercise",JSON.stringify(readingExercise)); 
                  localStorage.setItem("readingExerciseState","cached"); 
                  setIsFetching(false);
                  setOpenReading(true);   
                }else{
                  getExercise(lang, native, localStorage.getItem("currentPhrase"), "reading", JSON.stringify(readingPractice)).then(function(data){
                    readingPractice.readingIntro=data.readingIntro;
                    readingPractice.readingParagraph=data.readingParagraph;
                    readingPractice.questions=data.questions;
                    readingExerciseState="cached";
                    localStorage.removeItem("readingExerciseState");
                    localStorage.setItem("readingExerciseState","cached"); 
                    localStorage.setItem("readingExercise",JSON.stringify(readingExercise));  
                    setIsFetching(false);
                    setOpenReading(true);   
              })
                }
              })
        }
    })
      } else if (readingExerciseState === "floating"){
        updateTokensAPI(2).then(function(response){
          if (!response){
            alert('update tokens failed');
            setIsFetching(false);
            return false;
          }else if (response.body['message'] == 'Insufficient Tokens.'){
              setIsFetching(false);
              console.log('Insufficient funds, please reload tokens..........');
              alert('Insufficient Funds ..............');
              return false;
          }else{
            let data = [{ "Name": "custom:token-balance", "Value": response.body["tokens left"].toString()}];
            updateUserAttributesAPI(data);
            readingPractice.readingIntro=readingExercise.readingIntro;
            readingPractice.readingParagraph=readingExercise.readingParagraph;
            readingPractice.questions=readingExercise.questions;
            readingPractice.original_phrase=phrase.phraseOfTheDay.text;  
            readingExerciseState="cached";
            localStorage.removeItem("readingExerciseState");
            localStorage.setItem("readingExerciseState", "cached"); 
            setIsFetching(false);
            setOpenReading(true);
          }
        }
      )
      }else if (readingExerciseState==="cached"){
        readingPractice.readingIntro=readingExercise.readingIntro;
        readingPractice.readingParagraph=readingExercise.readingParagraph;
        readingPractice.questions=readingExercise.questions;
        readingPractice.original_phrase=phrase.phraseOfTheDay.text;      
        setIsFetching(false);
        setOpenReading(true);
      }
    }
  }, [])
  return (
    <>
      <li key="readingid">
        <img className="mx-auto h-72 w-72 rounded-full" src={readingimg} alt="" />
        <Link className="relative px-7 py-2 font-medium text-white group mt-4" onClick={() => learnExercise(true)}>
          <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-purple-500 group-hover:bg-purple-700 group-hover:skew-x-12"></span>
          <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-purple-700 group-hover:bg-purple-500 group-hover:-skew-x-12"></span>
          <span className="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-purple-600 -rotate-12"></span>
          <span className="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-purple-400 -rotate-12"></span>
          <span className="relative">{t('Reading')}</span>
          <div className="absolute -top-0 -right-0 -mt-3 -mr-4 px-3 py-1 h-9 w-9 rounded-full
                  bg-cover bg-center" style={{backgroundImage: `url(${tokenImgUrl})`}}>
            <div className="font-extrabold text-purple-500 text-lg pr-1 pt-0.5">2</div>
          </div>
        </Link>
      </li>

      {isFetching && 
          <div id="spinner" className="w-full">
            <Spinner/>
          </div>        
        }
    {/* Modal Pop up for reading exercise */}

    <Transition.Root show={openReading} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenReading}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                    onClick={() => setOpenReading(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:items-center lg:gap-x-8">


                    {/* Reading Exercise */}
                    <div className="bg-white shadow sm:rounded-lg col-span-12 p-2"> 
                    
                    <div className='pb-5'></div>

                      <div className="bg-sky-600">
                        <div className="px-4 py-5 sm:p-6">
                          <h2 className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-4xl">{t('Reading Lesson')}</h2>
                        </div>
                      </div>
                      <br/>
                      <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 m-4">
                        <div className="flex">
                          <div className="ml-3">
                            <p className="text-md text-yellow-700">
                              {readingPractice.readingIntro}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="border-t-4 border-b-4 border-violet-400 bg-violet-50 p-4 shadow-md m-4">
                        <div className="flex">
                          <div className="ml-3">
                            <h2 className="text-violet-800 pb-4">
                              Paragraph for reading
                            </h2>                              
                            <p className="text-md text-violet-800">
                              {readingPractice.readingParagraph}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="border-l-4 border-green-400  bg-green-50 p-4 m-4">
                        <div className="flex">
                          <div className="ml-3 text-green-900">
                              Questions:
                              <ul className='p-4'>
                              {readingPractice.questions.map((question) => (
                                <ListItemNumber key={question.id} count={question.id} text={question.ques} />
                              ))}
                              </ul>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  );
}
