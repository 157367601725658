import { MegaphoneIcon, BoltSlashIcon, ArrowPathIcon, UserCircleIcon,} from '@heroicons/react/20/solid'
import { Fragment, useState, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux';
import { loadDailyLessons} from '../slices/lessonsSlice';
import Spinner from './Spinner';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import speakingimg from '../img/speaking.jpg';
import moment from 'moment';
import { updateUserAttributesAPI } from './UpdateUserAttributesAPI';
import { sendCohortAPI,  retrievePhrasesAPI, retrieveS3DataAPI, getListeningExercise, getExercise, getNewPhrasesAPI } from './CohortAPI';
import { updateTokensAPI } from './TokenAPI';
import { speechAPI } from './SpeechAPI';
import tokenImgUrl from '../img/token.png';
import axios from 'axios'
import { listeningPractice } from './ListeningExercise';
import { writingPractice } from './WritingExercise';
import { readingPractice } from './ReadingExercise';
import { speakingPractice } from './SpeakingExercise';
import hash from 'object-hash';

var username;
let learnedLessons = [];
let isPanelOpen;
let lessonIndex=0;
let didGetLesson;
let phraseSet={};
const phrases = [
  { key: "text", explanation: '', heading: 'Phrase',  bgColor: 'bg-pink-600', language: "it-IT" },
  { key: "translation", explanation: '', heading: 'Translation', bgColor: 'bg-purple-600',  language: "en-US"  },
  { key: "pronunciation", explanation: '', heading: 'Phonetic Pronunciation', bgColor: 'bg-yellow-500' },
]

const tips = [
  { key: "grammarTip", explanation: '', heading: 'Grammar Tip',  bgColor: 'bg-pink-600' },
  { key: "culturalInsight", explanation: '', heading: 'Cultural Insight', bgColor: 'bg-purple-600' },
  { key: "conversationalTip", explanation: '', heading: 'Conversational Tip', bgColor: 'bg-yellow-500' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

async function getLessonsOnLoad(phrase){
  // when sideload begins, also load up the lessons from the phrases
  let lang = localStorage.getItem("learningLanguage");
  let native = localStorage.getItem("currentLanguage");
  let listeningExercise;
  let speakingExercise;
  let writingExercise;
  let readingExercise;
  let listeningExerciseState=localStorage.getItem("listeningExerciseState") || {};
  let readingExerciseState=localStorage.getItem("readingExerciseState") || {};
  let writingExerciseState=localStorage.getItem("writingExerciseState") || {};
  let speakingExerciseState=localStorage.getItem("speakingExerciseState") || {};

  if(localStorage.getItem("listeningExercise")!="undefined"){
    listeningExercise=JSON.parse(localStorage.getItem("listeningExercise"));
  }
  if(localStorage.getItem("readingExercise")!=null){
    readingExercise=JSON.parse(localStorage.getItem("readingExercise"));
  }
  if(localStorage.getItem("writingExercise")!=null){
    writingExercise=JSON.parse(localStorage.getItem("writingExercise"));
  }
  if(localStorage.getItem("speakingExercise")!=null){
    speakingExercise=JSON.parse(localStorage.getItem("speakingExercise"));
  }

  if(!listeningExercise || (listeningExerciseState === "cached" && listeningExercise.original_phrase != phrase.phraseOfTheDay.text) || listeningExerciseState === "floating" ){// make sure the practice is not in a cached state
    retrieveS3DataAPI(JSON.stringify(phrase.phraseOfTheDay.text), "listening").then(function(response){
      if(response){
        console.log("retrieved dat from S3 for Listening Lesson");
        return false;
      }else{
        getListeningExercise(lang, native, phrase.phraseOfTheDay.text, "listening", JSON.stringify(listeningPractice)).then(function(response){
          localStorage.setItem("listeningExercise", JSON.stringify(response));
          let listeningExercise= JSON.parse(localStorage.getItem("listeningExercise"));
          if(listeningExercise.original_phrase !== phrase.phraseOfTheDay.text ){
            localStorage.setItem("listeningExerciseState","floating");
          }
        });
      }
    })
}
if(!readingExercise || readingExerciseState === "cached" && readingExercise.original_phrase != phrase.phraseOfTheDay.text || readingExerciseState === "floating" ){
    retrieveS3DataAPI(JSON.stringify(phrase.phraseOfTheDay.text), "reading").then(function(response){
      if(response){
        console.log("retrieved dat from S3 for Reading Lesson");
        return false;
      }else{
        getExercise(lang, native, phrase.phraseOfTheDay.text, "reading", JSON.stringify(readingPractice)).then(function(data){
          localStorage.setItem("readingExercise", JSON.stringify(response));
          let readingExercise= JSON.parse(localStorage.getItem("readingExercise"));
          if(readingExercise.original_phrase !== phrase.phraseOfTheDay.text ){
            localStorage.setItem("readingExerciseState","floating");
          }
        })
      }
    })
}
if(!writingExercise || writingExerciseState === "cached" && writingExercise.original_phrase != phrase.phraseOfTheDay.text || writingExerciseState === "floating" ){

  retrieveS3DataAPI(JSON.stringify(phrase.phraseOfTheDay.text), "writing").then(function(response){
    if(response){
      console.log("retrieved dat from S3 for Writing Lesson");
      console.log(JSON.stringify(response.data));
      return false;
    }else{
      getExercise(lang, native, phrase.phraseOfTheDay.text, "writing", JSON.stringify(writingPractice) );
    }
  })
}
if(!speakingExercise || speakingExerciseState === "cached" && speakingExercise.original_phrase != phrase.phraseOfTheDay.text || speakingExerciseState === "floating" ){

  retrieveS3DataAPI(JSON.stringify(phrase.phraseOfTheDay.text), "speaking").then(function(response){
    console.log("retrievING dat from S3 for Speaking Lesson");
    if(response){
      console.log("retrieved dat from S3 for Speaking Lesson");
      return false;
    }else{
      console.log("get dat from S3 for Speaking Lesson");
      getExercise(lang, native, phrase.phraseOfTheDay.text, "speaking", JSON.stringify(speakingPractice) );
    }
  })
}
}
// inserted for sideload promise/async code to be tested
async function sideloadLessonsFromPhrases(user, phrases) {
  console.log("retrieving new lessons from phrases API .......");
  console.log(phrases);
  let currentContext=user.attributes['custom:context'];
  let currentLanguage=user.attributes['custom:learning-language'];
  let learningLevel=user.attributes['custom:learning-level'];
  let nativeLanguage=user.attributes['custom:native-language'];
  let learningLevelValue = 3;

  let data = JSON.stringify({
      "role": "user",
      "prompt": `You are a language tutor helping a native ${nativeLanguage} speaking person learn ${currentLanguage}. Assume the ${currentLanguage} is at a ${learningLevel} level and wants lessons presented at a ${learningLevel} level (which you won’t mention). Today they are learning the following three Phrases-of-the-day, ${phrases[0].phraseOfTheDay.text}, ${phrases[1].phraseOfTheDay.text}, ${phrases[2].phraseOfTheDay.text}, Provide the Translation, a Phonetic Pronunciation, a Vocabulary Expansion, a Grammar tip, a Cultural Insight, a Conversational Tip and a Role Play which uses the phrase in speaking to someone in the ${currentContext}. Speak to them in the first person in native ${nativeLanguage} and use their name to make it sound personal. Formulate the response in a json object. Do not reply to this query with anything else but the json object text. Use the following format: [{\r\n  \"phraseOfTheDay\": {\r\n    \"text\": \"\", {\r\n    \"translation\": \"\",\r\n    \"pronunciation\": \"\",\r\n    \"vocabularyExpansion\": {\r\n\r\n    },\r\n    \"grammarTip\": \"\",\r\n    \"culturalInsight\": \"\",\r\n    \"conversationalTip\": \"\",\r\n    \"rolePlay\": {\r\n\"situation\": \"\",\r\n      \"firstPerson\": \"\",\r\n      \"secondPerson\": \"\",\r\n      \"script\": {\r\n        \"Marco\": \"\",\r\n        \"Tour guide\": \"\",\r\n        \"Marco2\": \"\"\r\n      }\r\n    }\r\n  }\r\n }]"`,
      "model": "gpt-4o"
  });
  
  let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://z6hiegk6imdth2nijmtfhbwsay0hxkoa.lambda-url.us-west-2.on.aws/',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

  try {
    let response = await axios.request(config);
    let customAttrs =
    {
      "context": user.attributes['custom:context'],
      "level": user.attributes['custom:learning-level'],
      "native-language": user.attributes['custom:native-language'],
      "learning-language": user.attributes['custom:learning-language']
    }
    sendCohortAPI(user,response.data, "cohorts");
    localStorage.setItem("dailyPhrases",JSON.stringify(response.data));
    localStorage.setItem("dailyLessonsFromAPI",JSON.stringify(response.data));
    return response.data;
  } catch (error) {
      console.error(error);
  }
}

export function DailyPhrase() { 
  const dispatch = useDispatch();
  const user =  useSelector(state => state.user.userInfo);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [phraseOfTheDayText, setPhraseOfTheDayText] = useState('')
  const [isLimitReached, setIsLimitReached] = useState(false); 
  let [lessons, setLessons, setPhrases] = useState([]);
  const [audioSpeechUrl, setAudioSpeechUrl] = useState('');
  let [rolePlayScripts, setRolePlayScripts] = useState({});
  let [vocabularyExpansions, setVocabularyExpansions] = useState({});
  let [rolePlaySituation, setRolePlaySituation] = useState('');
  const audioRef= useRef();
  const setAudioPlayBack = () => {
    audioRef.current.playbackRate = 0.75;
  };
  // retrieve new phrases instead of cohorts
  const retrieveNewPhrases = useCallback((user)=>{
    setIsFetching(true);
    retrievePhrasesAPI(user).then((phrases) => {
      console.log("phrases from load call 1: " + JSON.stringify(phrases));
      localStorage.setItem("allPhrases", phrases);
      phraseSet=phrases;
      setLessons(phrases);
      setDailyPhrase(phrases[lessonIndex]);
      dispatch(loadDailyLessons(phrases));
      setIsFetching(false);   
    })
  })

  let simpleLessonRetrieve = useCallback((user) => {
    console.log("retrieving new lessons .......");
    setIsLimitReached(false);
    setIsFetching(true);

    getNewPhrasesAPI(user).then((lessons) => {
      console.log("phrases from load call AI query (not cached): "+JSON.stringify(lessons));
      phraseSet = lessons;
      console.log("Phrase set = "+ phraseSet);
      dispatch(loadDailyLessons(lessons));
      setLessons(lessons);
      setDailyPhrase(lessons[lessonIndex]);
      // setIsFetching(false);
      let updatedata = [{ "Name": "custom:timeout-token", "Value": moment().utc().valueOf().toString() }];
      updateUserAttributesAPI(updatedata);
      let customAttrs =
      {
        "context": user.attributes['custom:context'],
        "level": user.attributes['custom:learning-level'],
        "native-language": user.attributes['custom:native-language'],
        "learning-language": user.attributes['custom:learning-language']
      }
      sendCohortAPI(user, lessons, "cohorts");
    })
  }, []);

  let setDailyPhrase = (lesson)  => {
    console.log("phrases from load call 3"+JSON.stringify(lesson));
    setPhraseOfTheDayText(lesson.phraseOfTheDay.text);
    localStorage.setItem("currentPhrase",JSON.stringify(lesson));
  }

  function setDailyPhraseContent(lesson) {
    console.log("setDailyPhraseContent: " + lesson);
    setPhraseOfTheDayText(lesson.phraseOfTheDay.text);
    localStorage.setItem("currentPhrase",JSON.stringify(lesson));

    phrases.forEach(function(phrase) {
      phrase.explanation = lesson.phraseOfTheDay[phrase.key] 
    });
    tips.forEach(function(tip) {
     tip.explanation = lesson.phraseOfTheDay[tip.key] 
    });
    setRolePlayScripts(lesson.phraseOfTheDay.rolePlay.script);
    setVocabularyExpansions(lesson.phraseOfTheDay.vocabularyExpansion);
    setRolePlaySituation(lesson.phraseOfTheDay.rolePlay.situation);
    returnSpeech(phrases[0].explanation);
  }

  function setChoiceDailyPhrase(indx){
    localStorage.setItem("listeningExerciseState","floating");// if we update the phrase here it puts the practice into a floating state
    localStorage.setItem("readingExerciseState","floating");// if we update the phrase here it puts the practice into a floating state
    localStorage.setItem("writingExerciseState","floating");// if we update the phrase here it puts the practice into a floating state
    localStorage.setItem("speakingExerciseState","floating");// if we update the phrase here it puts the practice into a floating state

    let lessons = JSON.parse(localStorage.getItem("dailyLessonsFromAPI"));

    if(lessons.length < 2 ){
      window.alert("Something has gone wrong, there are no more phrases to load");
      return false;
    }
    if(indx <= 2){
      console.log("indx: "+indx);
      console.log("Lesson Index is now : " + lessonIndex);
      console.log(lessons[indx]);
      let phraseOfTheDayTextIndx = lessons[indx].phraseOfTheDay.text
      setPhraseOfTheDayText(phraseOfTheDayTextIndx);
      localStorage.setItem("currentPhrase",JSON.stringify(lessons[indx]));
      phrases[0].explanation = lessons[indx].phraseOfTheDay.text
      localStorage.setItem("listeningExerciseState","floating");
      getLessonsOnLoad(lessons[indx]);
      lessonIndex+=1;
    }else{
      setIsLimitReached(true);
      return true;
    }
  }

  let learnPhrase = useCallback((amount, user, lessons) => {
    console.log("learn phrase loop starts");
    lessons = JSON.parse(localStorage.getItem("dailyLessonsFromAPI"));
    let n_phraseSet = JSON.parse(localStorage.getItem("dailyPhrases"));
    if(lessons[0].phraseOfTheDay.translation == null && lessons[0].phraseOfTheDay.text != null){
      // this means that the lessons have not loaded yet, but the phrases HAVE loaded
      // now load the lessons from the phrases by sending them to the COHORT API
      console.log("phrases have loaded but not lessons")
      setIsFetching(true);
        const timer = setTimeout(() => {
          learnPhrase(1, user, lessons);
        }, 1000);
        return () => clearTimeout(timer);
    }else if (n_phraseSet[0].phraseOfTheDay.text != lessons[0].phraseOfTheDay.text){
      // this occurs when there is a lesson loaded into the cache but it does not match the current phrase, which initiates the timeout
      console.log("PHRASES AND LESSONS DO NO MATCH!!!!!");
      console.log("PHRASE:  " + n_phraseSet[0].phraseOfTheDay.text);
      console.log("LESSON:  " + lessons[0].phraseOfTheDay.text);
      const timer = setTimeout(() => {
        learnPhrase(1, user, lessons);
      }, 1000);
      return () => clearTimeout(timer);
    }else{
      // console.log("lessons have loaded")
      console.log("lesson set = "+ lessons);
      let tokenBalance = user.attributes['custom:token-balance'] || 0 ;
      setIsFetching(true);
      if (Number(tokenBalance) < amount){
        console.log('Insufficient funds, please reload tokens..........');
        alert('Insufficient Funds ..............'); 
        return false;
      }
      for(let i =0; i < learnedLessons.length; i++){
        console.log("i in learnedLessons: ");
        console.log(learnedLessons[i]);
        if(learnedLessons[i].phraseOfTheDay.text === lessons[lessonIndex].phraseOfTheDay.text){
          // figure out why the tokens are spent when re-using the same phrase
          console.log("not spending tokens looking at same phrase.........");
          // setDailyPhraseContent(lessons[lessonIndex]);
          setOpen(true);
          setIsFetching(false);
          isPanelOpen=true;
          return false;
        }
      }
        updateTokensAPI(amount).then((response) => {
          console.log("pushing : " + JSON.stringify(lessons[lessonIndex]) )
          learnedLessons.push(lessons[lessonIndex]);
          console.log(learnedLessons);
          // localStorage.setItem("learnedLessons",lessons);
          if (!response){
            console.log('update tokens failed');
            setIsFetching(false);
            return false;
          }
          if (response.body["message"] === 'Insufficient Tokens.'){
            setIsFetching(false);
            console.log('Insufficient funds, please reload tokens..........');
            alert('Insufficient Funds ..............');
            return false;
          }
          setDailyPhraseContent(lessons[lessonIndex]);
          setIsFetching(false);
          setOpen(true);
          isPanelOpen=true;
          // return false;
          didGetLesson=true;
          let data = [{ "Name": "custom:token-balance", "Value": response.body["tokens left"].toString()}];
          updateUserAttributesAPI(data);
        })
    }
  },[]);


  let returnSpeech = useCallback((text)  => {
    speechAPI(text).then((response) => {
      let uInt8Array = new Uint8Array(response.AudioStream.data);
      let arrayBuffer = uInt8Array.buffer;
      var blob = new Blob([arrayBuffer], {type: 'audio/mpeg'});
      const url = URL.createObjectURL(blob);
      setAudioSpeechUrl(url);
    })
  },[]);

  useEffect(() => {
    console.log("Lessons from USE EFFECT 2 " + JSON.stringify(lessons));
    if (user) {
      console.log("user exists");
      username = user.attributes['name'];
      // check to see if the user settings matches the current settings and if not, reset the lessonIndex
      //lessonINDEX LOGIC
      if(JSON.stringify(user.attributes['custom:learning-language']) != JSON.stringify(localStorage.getItem('learningLanguage')) ){
        console.log("USE EFFECT user attribute: "+ JSON.stringify(user.attributes));
        console.log(user.attributes['custom:learning-language'])
        console.log(localStorage.getItem('learningLanguage'))
        lessonIndex=0;
        localStorage.setItem("currentContext", user.attributes['custom:context']);
        localStorage.setItem("currentLanguage", user.attributes['custom:native-language']);
        localStorage.setItem("learningLanguage", user.attributes['custom:learning-language']);
        localStorage.setItem("learningLevel", user.attributes['custom:learning-level']);

      }
      if(JSON.stringify(user.attributes['custom:learning-level']) != JSON.stringify(localStorage.getItem('learningLevel')) ){
        console.log("USE EFFECT user attribute: "+ JSON.stringify(user.attributes));
        console.log(user.attributes['custom:learning-level'])
        console.log(localStorage.getItem('learningLevel'))
        lessonIndex=0;
        localStorage.setItem("currentContext", user.attributes['custom:context']);
        localStorage.setItem("currentLanguage", user.attributes['custom:native-language']);
        localStorage.setItem("learningLanguage", user.attributes['custom:learning-language']);
        localStorage.setItem("learningLevel", user.attributes['custom:learning-level']);

      }
      let userContextVal = hash(JSON.stringify(user.attributes['custom:context']))
      let userContextValStored = hash(JSON.stringify(localStorage.getItem('currentContext')))
      if(userContextVal != userContextValStored)
      {
        console.log (hash(JSON.stringify(user.attributes['custom:context'])))
        console.log (hash(JSON.stringify(localStorage.getItem('currentContext'))))
        lessonIndex=0;
        localStorage.setItem("currentContext", user.attributes['custom:context']);
        localStorage.setItem("currentLanguage", user.attributes['custom:native-language']);
        localStorage.setItem("learningLanguage", user.attributes['custom:learning-language']);
        localStorage.setItem("learningLevel", user.attributes['custom:learning-level']);
      }
      if(JSON.stringify(user.attributes['custom:native-language']) != JSON.stringify(localStorage.getItem('currentLanguage')) ){
        console.log("USE EFFECT user attribute: "+ JSON.stringify(user.attributes));
        console.log(user.attributes['custom:native-language'])
        console.log(localStorage.getItem('currentLanguage'))
        lessonIndex=0;
        localStorage.setItem("currentContext", user.attributes['custom:context']);
        localStorage.setItem("currentLanguage", user.attributes['custom:native-language']);
        localStorage.setItem("learningLanguage", user.attributes['custom:learning-language']);
        localStorage.setItem("learningLevel", user.attributes['custom:learning-level']);

      }
      // here we need to check if time is reached for new cohort generation or retireve from cohort 
      let cohortToken =  user.attributes['custom:timeout-token'] || 0;
      console.log(`${cohortToken} : Is the value ........ `)
      const rightNow = moment().utc().format("YYYY-MM-DD hh:mm:ss");
      const prevDay = moment(Number(cohortToken)).utc().format("YYYY-MM-DD hh:mm:ss");
      let differenceInHours = Math.abs(moment(rightNow).diff(prevDay, 'hours'));
      let differenceInSeconds = Math.abs(moment(rightNow).diff(prevDay, 'seconds'));
      // differenceInHours=parseFloat(differenceInHours+.15);
      let limiter =parseFloat(.12);
      console.log(`${differenceInSeconds} seconds since last cohort was generated`);
      if (differenceInSeconds > 1800){
        console.log(`generating brand new cohort : ${differenceInHours.toFixed(2)} since last cohort was generated`);
        setIsFetching(true);
        simpleLessonRetrieve(user); 
      }else{
        console.log(`NOT generating brand new cohort : ${differenceInHours} hours since last cohort was generated`);
        console.log("set fetching true in useState");
        console.log(isPanelOpen + ( " = open status"));
        // if setOpen is true
        if(document.getElementById('LearnIt') == null){
          setIsFetching(true);
        }
        retrievePhrasesAPI(user).then(function(data){
          localStorage.setItem("allPhrases",JSON.stringify(data));
          // side load all lessons from the init phrase of the day
          setIsLimitReached(false);
          lessons=data;
          setLessons(lessons);
          console.log("Lesson Index is now : " + lessonIndex);
          setDailyPhrase(lessons[lessonIndex]);
          getLessonsOnLoad(lessons[lessonIndex]);
          //BUG
          // IF a new set has loaded and the page refreshes the lesson index needs to be set to 0, but not if the lessons are being learned
          // lessonIndex=0;//put in to correct for lesson modules
          //**** */
          if(data[0].phraseOfTheDay.translation == null && data[0].phraseOfTheDay.text != null){
            sideloadLessonsFromPhrases(user, data);// this should sideload the query so the wait is less
          }
          setIsFetching(false);
        })
        console.log('retrieve locally stored lessons ........');
        if (lessons.length === 0){
          console.log('lessons length is 0 ................')
          setIsFetching(true);
          console.log(`retrieving existing cohort : ${differenceInHours} hrs since last cohort was generated`);
          retrievePhrasesAPI(user).then(function(data){
            console.log('lessons length is ................' + data.length)
            console.log('lessons content is ................' + JSON.stringify(data))
            setDailyPhrase(data[0]);
            setIsFetching(false);
            return;
          });
        } else {
          console.log('lessons length is ................' + lessons.length)
          // console.log('lessons content is ................' + JSON.stringify(lessons))
          // let lzs = JSON.parse(lessons);
          let lzs = lessons;
          setLessons(lzs);
          setDailyPhrase(lzs[0]);
          return;
        }
      }
    }
  }, [user]);
  return (
    <>
      <div className="pb-5 bg-purple-200 p-4 shadow-lg rounded-lg m-2">
        {isFetching && 
          <div id="spinner" className="w-full">
            <Spinner/>
          </div>        
        }
        <div className="flex items-center">
          <MegaphoneIcon className="h-8 w-8 text-purple-400" aria-hidden="true" />
          <div className="pl-4 text-purple-600 text-lg">
            {t('Phrase of the Day')}
          </div>
        </div>


        <div className="relative pt-2">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-purple-400 border-dashed" />
          </div>
          <div className="relative flex justify-left">
            <span className="px-2 text-sm text-gray-500"><BoltSlashIcon className="h-6 w-6 text-purple-400" aria-hidden="true" /></span>
          </div>
        </div>

        <p id= "phrase-day" className="mt-2 max-w-4xl text-lg p-4 text-blue-900 font-sans">
          {phraseOfTheDayText}
        </p>

        <div className="relative p-2">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-purple-400 border-dashed" />
          </div>
          <div className="relative flex justify-end">
              <span className="px-2 text-sm text-gray-500"><BoltSlashIcon className="h-6 w-6 text-purple-400" aria-hidden="true" /></span>
          </div>
        </div>

        <nav className="flex items-center justify-between">
          <div className="flex w-0 flex-1">
            <button
            type="button"
            className="relative group rounded-md bg-purple-700 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-400 focus-visible:outline shadow-purple-500"
            onClick={(event)=>learnPhrase(1, user, lessons)}
            >
            {t('Learn it')}
            <div className="absolute -top-3 -right-0 -mt-4 -mr-4 px-3 py-1 h-9 w-9 rounded-full
                  bg-cover bg-center" style={{backgroundImage: `url(${tokenImgUrl})`}}>
              <div className="font-extrabold text-purple-500 text-lg pr-1 pt-0.5">1</div>
            </div>
          </button>
          </div>
          <div className="flex w-0 flex-1 justify-end">
            <div className='pr-2'>{''}</div>    
            { !isLimitReached && 
            <div className="tooltip tooltip-left tooltip-warning" data-tip="Refresh to learn next phrase">
              <button 
                type="button"
                onClick={() => setChoiceDailyPhrase(lessonIndex+1)}
                className="rounded-full p-2 text-white shadow-md shadow-purple-500 bg-purple-700 hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <ArrowPathIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
            }
            {isLimitReached && 
              <div className="tooltip tooltip-left tooltip-warning" data-tip="Refresh Limit Reached!">
              <button 
                type="button"
                className="rounded-full p-2 text-white shadow-sm bg-gray-400"
              >
                <ArrowPathIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
            }
          </div>
        </nav>
      </div>

      {/* Learn Daily Phrase Content */}

      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" id='LearnIt' className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-10 w-10 fill-purple-500 stroke-white glow" aria-hidden="true" />
                  </button>

                  <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:items-center lg:gap-x-8">

                    
                    {/* Phrase translation */}
                   <div className="bg-white shadow sm:rounded-lg col-span-12">
                        <div className="px-4 py-5 sm:p-6">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">
                              All right, {username}, today's phrase will help you with basic navigation during your trip.
                            </h3>
                            <div className="mt-2 max-w-4xl text-sm text-gray-500">
                            <ul className="mt-3 grid grid-cols-12 gap-6">
                                    {phrases.map((phrase) => (
                                    <li key={phrase.heading} className="col-span-12 flex rounded-md shadow-sm w-full">
                                      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow w-full">
                                        <h3 className={classNames(
                                            phrase.bgColor, `px-4 py-2 sm:px-6 text-base font-semibold leading-6 text-white`)}>
                                              {t(phrase.heading)}
                                        </h3>
                                        <div className="px-4 py-5 sm:p-6 bg-gray-300 text-gray-950 text-lg font-normal">
                                          {phrase.explanation}
                                        </div>
                                      </div>
                                    </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-5 mr-5">
                                <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                >
                                  <figure className='inline-flex items-center w-72 lg:w-fit'>
                                    <figcaption>{t('Play Phrase Audio')}:</figcaption>
                                    <pre>  </pre>
                                    <audio id="audioControl" controls src={audioSpeechUrl} ref={audioRef} onCanPlay={() => setAudioPlayBack()}></audio> 
                                  </figure>
                                </button>
                            </div>
                        </div>
                    </div>
                    

                    {/* Vocabulary Expansion */}
                    {Object.keys(vocabularyExpansions).length > 0 && 
                      <div className="bg-gray-200 shadow sm:rounded-lg col-span-12">
                          <div className="px-4 py-5 sm:p-6">
                              <h3 className="text-base font-semibold leading-6 text-white bg-purple-400 w-full p-2 rounded-md">{t('Vocabulary Expansion')}</h3>
                              <div className="max-w-4xl text-sm text-gray-500">
                                  <ul className="grid grid-cols-1 gap-6">
                                      <li key ="voc" className="col-span-1 flex rounded-md shadow-sm">
                                          <div className="flex flex-1 items-center justify-between rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                              <div className="flex-1 px-4 py-2 text-sm flex-wrap">
                                              <ul>
                                              {Object.entries(vocabularyExpansions).map(([key, value], vocabularyIndex) => (                
                                                <li key={vocabularyIndex}>
                                                  <span className='text-lg font-normal pr-2'>{key}</span> - <span className='text-lg font-normal'>{value}</span></li>  
                                                ))}
                                            </ul>
                                              </div>
                                          </div>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                    }

                    {/* Tips */}
                    <div className="bg-white shadow sm:rounded-lg col-span-12">
                        <div className="px-4 py-5 sm:p-6">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">Tips
                            </h3>
                            <div className="mt-2 max-w-4xl text-sm text-gray-500">
                            <ul className="mt-3 grid grid-cols-1 gap-6">
                              {tips.map((tip) => (
                              <li key={tip.heading} className="col-span-1 flex rounded-md shadow-sm">
                                <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow w-full">
                                  <h3 className={classNames(
                                      tip.bgColor, `px-4 py-2 sm:px-6 text-base font-semibold leading-6 text-white`)}>
                                        {t(tip.heading)}
                                  </h3>
                                  <div className="px-4 py-5 sm:p-6 bg-gray-300 text-gray-950 text-lg font-normal">
                                    {tip.explanation}
                                  </div>
                                </div>
                              </li>
                              ))}
                            </ul>
                            </div>
                        </div>
                    </div>

                    {/* Role Play */}
                    {Object.keys(rolePlayScripts).length > 0 &&
                      <div className="bg-gray-100 shadow sm:rounded-lg col-span-12">
                        <div className="sm:p-6">
                            <h3 className="text-base font-semibold leading-6  text-white bg-purple-400 p-2 rounded-t-md">{t('Role Play')}</h3>
                            <h3 className="text-base font-normal leading-6 text-gray-600 bg-purple-200 p-1">{rolePlaySituation}</h3>
                            <div className="max-w-4xl bg-white p-4 grid grid-cols-1 gap-4">
                            {Object.entries(rolePlayScripts).map(([key, value], scriptIndex) => (  
                              <div key={scriptIndex} className={classNames(
                                 scriptIndex % 2 ? 'chat-end' : 'chat-start',
                                 'chat'
                                 )}>
                                 <div className="chat-image avatar">
                                   <div className="w-10 rounded-full">
                                       {scriptIndex % 2 ? <img alt="guide" src={speakingimg} /> : <UserCircleIcon /> }  
                                   </div>
                                 </div>
                                 <div className="chat-bubble chat-bubble-warning text-lg font-normal">{value}</div>
                               </div>
                              ))}
                            </div>
                        </div>
                      </div>
                    }
                 

                    {/* Attention */}
                    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 col-span-12">
                        <div className="flex">
                            <div className="flex-shrink-0">
                            </div>
                            <div className="ml-3">
                            <p className="text-sm text-yellow-700">
                                Remember, {username}, practicing these phrases will help you feel more at ease during your travels. And don't hesitate to use them as much as possible while you're in Italy!
                            </p>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  {/* } */}
    </>
  );
}


